import React, { useState, ChangeEvent } from 'react';
import 'tailwindcss/tailwind.css';
import { readRcFile, processRcDialogs, SubView, Container } from '../helpers/translation';
import callOpenAI from '../helpers/OpenAPI';
import axios from 'axios';
import { PushSpinner } from "react-spinners-kit";
import './RcTranslate.css';

const RcTranslate: React.FC = () => {
  const [context, setContext] = useState<string>('');
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [translatedFileReady, setTranslatedFileReady] = useState<boolean>(false);
  const [translatedDialogs, setTranslatedDialogs] = useState<Container[]>([]);
  const [progress, setProgress] = useState<string>("");

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    console.log("file", file);
    if (file) {
      setUploadedFile(file);
      setTranslatedFileReady(false);
    }
  };

  const sendToServer = async (dialogs: Container[], file: File) => {
    const formData = new FormData();
    formData.append('dialogs', JSON.stringify(dialogs));
    formData.append('file', file);

    try {
      setProgress("Creating file...");
      const response = await axios.post('https://bikes.techlara.se/convert_file', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob'
      });
      
      if (response.data) {
        // Create a download link
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `translated_${file.name}`); // Use original filename
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        
        setTranslatedFileReady(true);
        setProgress("");
      }
    } catch (error) {
      console.error("Error sending data to server:", error);
      setProgress("Error creating file...");
    }
  }

  const startTranslation = async () => {

    console.log("uploadedFile", uploadedFile);
    if (!uploadedFile) {
      alert('No file uploaded');
      return;
    }

    const content = await readRcFile(uploadedFile);
    if (content) {
      const parsedDialogs: Container[] = processRcDialogs(content);
      console.log("parsedDialogs", parsedDialogs);
      
      if (parsedDialogs) {
        
        const translatedDialogsTemp: Container[] = []; // Temporary array to store translated dialogs

        for (const dialog of parsedDialogs) {
          try {
            const translatedDialog = await callOpenAI(dialog);
            if (translatedDialog) {
              dialog.values = translatedDialog;
              console.log("translatedDialog", translatedDialog);
            } else {
              console.log("Error translating dialog:", dialog);
            }

            translatedDialogsTemp.push(dialog);
            setProgress(`Translating parts: ${translatedDialogsTemp.length}/${parsedDialogs.length}`);
          } catch (error) {
            console.error("Translation error:", error);
            translatedDialogsTemp.push(dialog);
            setProgress(`Translating parts: ${translatedDialogsTemp.length}/${parsedDialogs.length}`);
          }
        }

        setTranslatedDialogs(translatedDialogsTemp); // Set state once all translations are done
        sendToServer(translatedDialogsTemp, uploadedFile);

      } else {
        alert('Error processing the RC file');
        
      }
    } else {
      alert('Error reading the file. Make sure it is a valid .rc file');
      setUploadedFile(null);
    }
  }

  const handleTranslateFile = () => {
    
    if (uploadedFile) {
      setProgress("Processing file...");
      startTranslation();
      //sendToServer(translatedDialogs, uploadedFile);
    } else {
      alert('Please upload a file first!');
    }
  };

  const handleDownloadFile = () => {
    if (translatedFileReady) {
      alert('Download started!'); // Simulate download
    } else {
      alert('File not translated yet!');
    }
  };

  const ContextView = () => {
    return (
      <div className="context-container">
      <label htmlFor="context" className="context-label">
        Context:
      </label>
      <input
        type="text"
        className='input-context'
        id="context"
        value={context}
        onChange={(e) => setContext(e.target.value)}
        placeholder="Enter context here..."
      />
    </div>
    );
  };

  const UploadFileView = () => {
    return (
      <div className="mb-4 flex items-center space-x-4">
          <label className="block">
            <input
              type="file"
              onChange={handleFileUpload}
              className="hidden"
              id="upload-file"
            />
          </label>
        </div>
    );
  };

  const TranslateFileButton = () => {
    return (
      <div className="mb-4 ">
      <button
        type="button"
        className="button"
        onClick={handleTranslateFile}
      >
        Translate file
      </button>
    </div>
    );
  };

  const DownloadFileButton = () => {
    return (
      <div className="mt-6">
      <button
        type="button"
        className={`px-6 py-3 rounded shadow text-white ${
          translatedFileReady
            ? 'bg-green-500 hover:bg-green-600'
            : 'bg-gray-400 cursor-not-allowed'
        }`}
        onClick={handleDownloadFile}
        disabled={!translatedFileReady}
      >
        Download translated file
      </button>
    </div>
    );
  };

  const ProgressView = () => {
    return (
      <div className="progress-container">
        
        {progress !== "" ? (
          <PushSpinner className="progress-spinner" size={20} color="#ffffff" loading={true} />
        ) : (
          <></>
        )}

        {progress.includes("error") ? (
          <p className="progress-message error-message">{progress}</p>
        ) : (
          <p className="progress-message">{progress}</p>
        )}
      </div>
    );
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 p-6">
      <div className="bg-white p-6 rounded shadow-md w-full max-w-lg">
        {ContextView()}
        {UploadFileView()}
        {ProgressView()}
        {TranslateFileButton()}
      </div>
    </div>
  );
}

export default RcTranslate;