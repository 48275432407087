import './App.css';
import RcTranslate from './code/views/RcTranslate';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <RcTranslate/>
      </header>
      
    </div>
  );
}

export default App;
