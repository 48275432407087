/* eslint-disable */
export interface Container {
  caption?: string;
  values: SubView[];
}

export interface SubView {
  line: string;
  orig: string;
  translated: string;
}

/**
 * Parses RC file content and extracts dialog information
 * @param {string} content - The RC file content
 * @returns {Dialog[]} Array of dialog objects
 */
export const parseRcDialog = (content: string): Container[] => {
  const dialogs: Container[] = [];
  let currentDialog: Container | null = null;
  let currentValues: SubView[] = [];
  let inDialog = false;
  let hasBegun = false;

  // Split content into lines and process each line
  const lines = content.split('\n');
  console.log("no lines", lines.length);
  lines.forEach((line, index) => {
    line = line.trim();
    line = line.replace(/\s+/g, ' ');

    // Start of dialog section
    if (line.includes('DIALOGEX') && !inDialog) {
      inDialog = true;
      currentValues = [];
      currentDialog = { values: [] };
      return;
    }

    // Get caption
    if (inDialog && line.startsWith('CAPTION')) {
      const caption = line.split('"')[1];
      currentDialog!.caption = caption;
      return;
    }

    // Parse controls inside BEGIN/END block
    if (inDialog && line === 'BEGIN') {
      hasBegun = true;
      return;
    }

    if (inDialog && line === 'END') {
      inDialog = false;
      hasBegun = false;
      currentDialog!.values = currentValues;
      dialogs.push(currentDialog!);
      return;
    }

    // Parse control types
    if (inDialog && line && hasBegun) {


      const match = line.match(/^\s*\w+\s+"([^"]+)"/);
      if (match) {
        const container = match[1].trim(); // Get the text inside the first quotes
        currentValues.push({ 
          line: index.toString(), 
          orig: container, 
          translated: '' // Removed the extra text since we don't need it
        });
      }

      // old code
      // const parts = line.split(' ');
      // const container = parts[0].trim(); // Get the first word as control type
      // const text = parts.slice(1).join(' '); // Get the rest of the parts
      // currentValues.push({ lineNumber: index.toString(), part1: container, part2: text });
    }
  });

  return dialogs;
};

/**
 * Reads and processes RC file content
 * @param {string} content - The RC file content
 * @returns {Container[]|null} Processed dialog data or null if error
 */
export const processRcDialogs = (content: string): Container[] => {
  if (!content) {
    console.log("No content");
    return [];
  }
  
  try {
    console.log("inside processRcDialogs");
    const dialogs = parseRcDialog(content);
    return dialogs;
  } catch (error) {
    console.error('Error processing RC dialogs:', error);
    return [];
  }
};

/**
 * Reads RC file content
 * @param {File} file - The RC file to read
 * @returns {Promise<string|null>} File content or null if error
 */
export const readRcFile = async (file: File): Promise<string | null> => {
  try {
    if (!file.name.endsWith('.rc')) {
      throw new Error('File must have .rc extension');
    }

    return await file.text();
  } catch (error) {
    console.error('Error reading file:', error);
    return null;
  }
};

/**
 * Extract numerical values from a line
 * @param {string} line - The line to parse
 * @returns {number[]|null} Array of numbers or null if no match
 */
export const extractNumericalValues = (line: string): number[] | null => {
  const match = line.match(/(\d+),\s*(\d+),\s*(\d+),\s*(\d+)/);
  if (match) {
    return [
      parseInt(match[1]),
      parseInt(match[2]),
      parseInt(match[3]),
      parseInt(match[4])
    ];
  }
  return null;
};


