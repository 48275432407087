import { OpenAI } from "openai";
import { SubView } from "./translation";

interface Prompt {
  values: SubView[]; // Adjust the type based on the actual structure of 'values'
}

interface Message {
  content: Array<{ text?: { value: string } } | any>; // Allow any structure
}

const callOpenAI = async (prompt: Prompt): Promise<SubView[] | null> => {

  const promptString = JSON.stringify(prompt.values);
  
  console.log("promptString", promptString);
  try {
    const client = new OpenAI({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY as string,
      dangerouslyAllowBrowser: true,
    });

    const thread = await client.beta.threads.create();

    client.beta.threads.messages.create(thread.id, {
      role: "user",
      content: promptString,
    });

    let run = await client.beta.threads.runs.createAndPoll(thread.id, {
      assistant_id: process.env.REACT_APP_OPENAI_ASSISTANT_ID as string,
    });

    if (run.status === 'completed') {
      const messages = await client.beta.threads.messages.list(run.thread_id);

      console.log("messages", messages);

      const lastMessage: Message = messages.data[0];
      const content = lastMessage.content.find(c => c.text)?.text?.value; // Safely find and access 'text'
      if (content) {
        const object = JSON.parse(content) as { result: SubView[] };
        console.log("object", object);
        return object.result;
      }

      return null;
    } else {
      console.log("run.status", run.status);
      return null;
    }

  } catch (err) {
    console.log("err", err);
    return null;
  }
};

export default callOpenAI; 